/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import { CookiesProvider } from 'react-cookie';
// import "./src/styles/global.css"
import 'what-input';

// For lg/xl/2xl screens only!
const setGutterWidth = () => {
  const root = document.documentElement;
  let width = 0;
  if (root.clientWidth >= 1800) {
    width = 1800
  } else if (root.clientWidth > 1024) {
    width = 1366;
  } else {
    width = 1024;
  }
  root.style.setProperty('--gutter-width', `${(root.offsetWidth - width) / 2}px`);
}

setGutterWidth();

window.addEventListener('resize', setGutterWidth);

export const wrapRootElement = ({ element }) => (
  <>
    <CookiesProvider>{element}</CookiesProvider>
    <link rel="stylesheet" type="text/css" href="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.css" />
    {/* <script src="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.js"></script> */}
  </>
)